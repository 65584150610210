import { Box, Container, Stack } from "@chakra-ui/react";
import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import { AutoSubmit } from "../components/AutoSubmit";
import CalculatorForm from "../components/CalculatorForm";
import { ContactForm } from "../components/ContactForm";
import Result from "../components/Result";
import CalculatorVersionProps from "./CalculatorVersion";

function Default({ isSmallScreen }: CalculatorVersionProps) {
  return (
    <>
      <AppHeader />
      <Container maxW="1000px">
        <Box p={8}>
          <Stack direction={isSmallScreen ? "column" : "row"} spacing="8">
            <CalculatorForm minWidth={150} />
            <Result />
          </Stack>
        </Box>
        <AppFooter />
      </Container>
      <ContactForm isV2={false} />
      <AutoSubmit />
    </>
  );
}

export default Default;
