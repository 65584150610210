import { fv, pmt, pv } from "financial";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import {
  ANNUAL_COMPOUND_RATE,
  INFLATION,
  LIFE_SPAN,
  RETIREMENT_CAP_RATE,
} from "../components/Result";
import { Input, Result, useInput } from "../InputContext";
import { useDebounce } from "../useDebounce";
import { useSubmit } from "./useSubmit";

const getYearsToSave = (idealRetirementAge: number, age: number): number =>
  idealRetirementAge - age;

const getRetirementGoalFutureValue = (
  idealRetirementAge: number,
  monthlyRetirementSpending: number,
  yearsToSave: number
): number => {
  const yearsToUse = LIFE_SPAN - idealRetirementAge;

  const adjustedRetirementGoal = fv(
    INFLATION,
    yearsToUse,
    -monthlyRetirementSpending * 12,
    0
  );

  return fv(INFLATION, yearsToSave, 0, -adjustedRetirementGoal);
};

const getRetirementGoal = (
  yearsToSave: number,
  fvRetirementGoal: number,
  idealRetirementAge: number,
  monthlyRetirementSpending: number
) => {
  const yearsToUse = LIFE_SPAN - idealRetirementAge;

  const adjustedRetirementSpending = fv(
    INFLATION,
    yearsToSave,
    0,
    -monthlyRetirementSpending * 12
  );

  return pv(
    RETIREMENT_CAP_RATE,
    yearsToUse,
    -adjustedRetirementSpending,
    -fvRetirementGoal
  );
};

const getNeededMonthlySavings = (
  currentSavings: number,
  retirementGoal: number,
  yearsToSave: number
): number =>
  pmt(ANNUAL_COMPOUND_RATE, yearsToSave, currentSavings, -retirementGoal) / 12;

export const getResult = ({
  age,
  currentSavings,
  idealRetirementAge,
  monthlyRetirementSpending,
  monthlySavings,
}: Omit<Input, "income">): Result => {
  const yearsToSave = getYearsToSave(idealRetirementAge, age);
  const fvRetirementGoal = getRetirementGoalFutureValue(
    idealRetirementAge,
    monthlyRetirementSpending,
    yearsToSave
  );
  const retirementGoal = getRetirementGoal(
    yearsToSave,
    fvRetirementGoal,
    idealRetirementAge,
    monthlyRetirementSpending
  );
  const neededMonthlySavings = getNeededMonthlySavings(
    currentSavings,
    retirementGoal,
    yearsToSave
  );

  return {
    monthlySavings,
    neededMonthlySavings,
  };
};

export const useSubmitResult = () => {
  const { values, submitForm } = useFormikContext<Input>();

  const { contactFormInput } = useInput();

  const {
    idealRetirementAge,
    age,
    monthlyRetirementSpending,
    currentSavings,
    monthlySavings,
    income,
  } = useDebounce(values, 200);

  const neededMonthlySavings = useMemo(
    () =>
      getResult({
        idealRetirementAge,
        age,
        monthlyRetirementSpending,
        currentSavings,
        monthlySavings,
      }).neededMonthlySavings,
    [
      age,
      currentSavings,
      idealRetirementAge,
      monthlyRetirementSpending,
      monthlySavings,
    ]
  );

  const { submit: submitEmail } = useSubmit();

  const submitResult = useCallback(async () => {
    const { email, firstName, lastName, phone, referredBy } = contactFormInput!;
    if (
      age > 0 &&
      currentSavings >= 0 &&
      monthlySavings >= 0 &&
      idealRetirementAge > age &&
      idealRetirementAge <= LIFE_SPAN &&
      monthlyRetirementSpending >= 0 &&
      neededMonthlySavings >= 0
    ) {
      submitForm();
      await submitEmail({
        age,
        amountAddingMonthly: monthlySavings,
        currentlySaved: currentSavings,
        email,
        idealRetirementAge,
        income,
        monthlyAtRetirement: monthlyRetirementSpending,
        name: `${firstName} ${lastName}`,
        phoneNumber: phone,
        referredBy,
        isV2: true,
      });
    }
  }, [
    age,
    contactFormInput,
    currentSavings,
    idealRetirementAge,
    income,
    monthlyRetirementSpending,
    monthlySavings,
    neededMonthlySavings,
    submitEmail,
    submitForm,
  ]);

  return submitResult;
};

export const useAutoSubmitResult = () => {
  const { values, submitForm } = useFormikContext<Input>();

  const {
    idealRetirementAge,
    age,
    monthlyRetirementSpending,
    currentSavings,
    monthlySavings,
  } = useDebounce(values, 200);

  const neededMonthlySavings = useMemo(
    () =>
      getResult({
        idealRetirementAge,
        age,
        monthlyRetirementSpending,
        currentSavings,
        monthlySavings,
      }).neededMonthlySavings,
    [
      age,
      currentSavings,
      idealRetirementAge,
      monthlyRetirementSpending,
      monthlySavings,
    ]
  );

  useEffect(() => {
    if (
      age > 0 &&
      currentSavings >= 0 &&
      monthlySavings >= 0 &&
      idealRetirementAge > age &&
      idealRetirementAge <= LIFE_SPAN &&
      monthlyRetirementSpending >= 0 &&
      neededMonthlySavings >= 0
    ) {
      submitForm();
    }
  }, [
    age,
    currentSavings,
    idealRetirementAge,
    monthlyRetirementSpending,
    monthlySavings,
    neededMonthlySavings,
    submitForm,
  ]);
};
