import "./App.css";
import {
  Box,
  ChakraProvider,
  Container,
  extendTheme,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import ReactGA from "react-ga4";
import AppHeader from "./components/AppHeader";
import CalculatorForm from "./components/CalculatorForm";
import Result from "./components/Result";
import { initialInput, InputProvider, Input } from "./InputContext";
import { Fonts } from "./Fonts";
import { ContactForm } from "./components/ContactForm";
import { Formik, FormikProvider, setIn } from "formik";
import { useEffect, useState } from "react";
import Router from "./pages";

const theme = extendTheme({
  fonts: {
    heading: "Rounded Elegance",
  },
});

function App() {
  const [input, setInput] = useState<Input>(initialInput);

  useEffect(() => {
    ReactGA.initialize("G-4Z61DGWYDY");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <InputProvider input={input} setInput={setInput}>
        <Formik initialValues={initialInput} onSubmit={setInput}>
          <Router />
        </Formik>
      </InputProvider>
    </ChakraProvider>
  );
}

export default App;
