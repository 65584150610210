import {
  Box,
  Button,
  Center,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useFormik, useFormikContext } from "formik";
import { useMemo } from "react";

import * as Yup from "yup";
import "yup-phone";
import { useSubmit } from "../hooks/useSubmit";

import { Input as IInput, useInput } from "../InputContext";

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First name too short")
    .max(30, "First name too long")
    .required("First name is required"),
  lastName: Yup.string()
    .min(3, "Last name too short")
    .max(30, "Last name too long")
    .required("Last name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .phone("US", true, "Must be a valid US phone number")
    .required("Phone number is required"),
  referredBy: Yup.string()
    .min(3, "Referred by too short")
    .max(60, "Referred by too long")
    .required("Referred by is required"),
});

const ErrorText = ({ text }: { text: String }) => {
  return <Text color="red.500">{text}</Text>;
};

export const ContactForm = ({ isV2 }: { isV2: boolean }) => {
  const {
    contactUsModal: { isOpen, onClose },
    setContactFormInput,
  } = useInput();

  const {
    values: {
      age,
      currentSavings,
      idealRetirementAge,
      income,
      monthlyRetirementSpending,
      monthlySavings,
    },
  } = useFormikContext<IInput>();

  const { state: formState, submit: submitForm } = useSubmit();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      referredBy: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (!isV2) {
        await submitForm({
          age,
          currentlySaved: currentSavings,
          idealRetirementAge,
          amountAddingMonthly: monthlySavings,
          email: values.email,
          name: `${values.firstName} ${values.lastName}`,
          monthlyAtRetirement: monthlyRetirementSpending,
          phoneNumber: values.phone,
          income,
          referredBy: values.referredBy,
        });
      } else {
        setContactFormInput?.({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          referredBy: values.referredBy,
        });
      }

      onClose();
    },
  });

  const { errors, touched, handleSubmit } = useMemo(() => {
    const { errors, touched, validateForm, handleSubmit } = formik;
    return { errors, touched, validateForm, handleSubmit };
  }, [formik]);

  return (
    <Modal isOpen={isOpen} onClose={() => !isV2 && onClose()} isCentered>
      <ModalOverlay />
      <ModalContent>
        {formState !== "pending" && (
          <>
            <ModalHeader>Contact Us</ModalHeader>
            {!isV2 && <ModalCloseButton />}
            <ModalBody>
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <FormLabel htmlFor={"firstName"}>First Name</FormLabel>
                  {errors.firstName && touched.firstName && (
                    <ErrorText text={errors.firstName} />
                  )}
                  <Input
                    id={"firstName"}
                    name={"firstName"}
                    value={formik.values.firstName}
                    type="firstName"
                    onChange={formik.handleChange}
                  />
                </Box>

                <Box mt={4}>
                  <FormLabel htmlFor={"lastName"}>Last Name</FormLabel>
                  {errors.lastName && touched.lastName && (
                    <ErrorText text={errors.lastName} />
                  )}
                  <Input
                    id={"lastName"}
                    name={"lastName"}
                    value={formik.values.lastName}
                    type="lastName"
                    onChange={formik.handleChange}
                  />
                </Box>

                <Box mt={4}>
                  <FormLabel htmlFor={"email"}>Email</FormLabel>
                  {errors.email && touched.email && (
                    <ErrorText text={errors.email} />
                  )}
                  <Input
                    id={"email"}
                    name={"email"}
                    value={formik.values.email}
                    type="email"
                    onChange={formik.handleChange}
                  />
                </Box>

                <Box mt={4}>
                  <FormLabel htmlFor={"phone"}>Phone Number</FormLabel>
                  {errors.phone && touched.phone && (
                    <ErrorText text={errors.phone} />
                  )}
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <Input
                      type="tel"
                      id={"phone"}
                      name={"phone"}
                      value={formik.values.phone}
                      placeholder="Phone Number"
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                </Box>

                <Box mt={4}>
                  <FormLabel htmlFor={"referredBy"}>Referred By</FormLabel>
                  {errors.referredBy && touched.referredBy && (
                    <ErrorText text={errors.referredBy} />
                  )}
                  <Input
                    id={"referredBy"}
                    name={"referredBy"}
                    value={formik.values.referredBy}
                    placeholder="Referred By"
                    onChange={formik.handleChange}
                  />
                </Box>
              </form>
            </ModalBody>

            <ModalFooter>
              <Box w="100%">
                <Button
                  onClick={() => handleSubmit()}
                  minW="100%"
                  type="submit"
                  color="white"
                  bgColor={"#005981"}
                >
                  Submit
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
        {formState === "pending" && (
          <Box p="20">
            <Center>
              <Spinner size="xl" />
            </Center>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};
