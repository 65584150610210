import { Box, Image, Center, Flex } from "@chakra-ui/react";

const AppHeader = () => {
  return (
    <Flex
      top={0}
      zIndex={100}
      position="sticky"
      backgroundColor="#005982"
      w="100%"
    >
      <Box position="relative" w="100%">
        <Center>
          <Image src={"/banner.png"} alt={"Calculator"} />
        </Center>
      </Box>
    </Flex>
  );
};

export default AppHeader;
