import { VStack, FormLabel, Input, InputProps, Button } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useSubmitResult } from "../hooks/useGetResult";
import { Input as CalculatorInput } from "../InputContext";

interface InputFieldProps {
  label: string;
  formikLabel: string;
  value?: number;
  onChange: InputProps["onChange"];
}

export const InputField = ({
  label,
  formikLabel,
  value = undefined,
  onChange = undefined,
}: InputFieldProps) => {
  return (
    <>
      <FormLabel htmlFor={formikLabel}>{label}</FormLabel>
      <Input
        id={formikLabel}
        name={formikLabel}
        value={value}
        type="number"
        onChange={onChange}
      />
    </>
  );
};

type CalculatorFormProps = {
  minWidth?: string | number;
  isV2?: boolean;
};

const CalculatorForm = (props: CalculatorFormProps) => {
  const { handleChange, values } = useFormikContext<CalculatorInput>();
  const onCalculate = useSubmitResult();

  return (
    <VStack textAlign={"left"} alignItems={"left"} {...props}>
      <InputField
        label="Age"
        formikLabel="age"
        value={values.age}
        onChange={handleChange}
      />
      <InputField
        label="Ideal Retirement Age"
        formikLabel="idealRetirementAge"
        value={values.idealRetirementAge}
        onChange={handleChange}
      />
      <InputField
        label="Monthly Amount You Want At Retirement"
        formikLabel="monthlyRetirementSpending"
        value={values.monthlyRetirementSpending}
        onChange={handleChange}
      />
      <InputField
        label="Amount Saved Currently"
        formikLabel="currentSavings"
        value={values.currentSavings}
        onChange={handleChange}
      />
      <InputField
        label="Amount Adding to Savings Monthly"
        formikLabel="monthlySavings"
        value={values.monthlySavings}
        onChange={handleChange}
      />
      {props.isV2 && (
        <Button color="white" bgColor={"#005981"} onClick={onCalculate}>
          Calculate
        </Button>
      )}
    </VStack>
  );
};

export default CalculatorForm;
