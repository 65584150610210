import { useMediaQuery } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import V1 from "./V1";
import V2 from "./V2";

const AppRoutes = () => {
  const [isSmallScreen] = useMediaQuery("(max-width: 888px)");

  return (
    <Routes>
      <Route path="/" element={<V1 isSmallScreen={isSmallScreen} />} />
      <Route path="v2" element={<V2 isSmallScreen={isSmallScreen} />} />
    </Routes>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default Router;
