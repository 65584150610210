import { Box, Text } from "@chakra-ui/react";

const AppFooter = () => (
  <Box textAlign="center" mt="8" mb="8">
    <Text fontSize="small">
      This analysis is hypothetical and for illustrative and informational
      purposes only and should not be construed as an investment recommendation
      or solicitation. Actual results will vary, perhaps to a significant
      degree. The rates of return do not represent any actual investment and
      cannot be guaranteed. Any investment involves potential loss of principal.
      This illustration does not take taxes, advisory fees, commissions,
      inflation, or other expenses into account. Please consult a financial
      professional to discuss your individual situation prior to making any
      investment decision.
    </Text>
  </Box>
);

export default AppFooter;
