import axios from "axios";
import React, { useCallback } from "react";

interface UseSubmitProps {
  name: string;
  email: string;
  phoneNumber: string;
  age: number;
  income: number;
  idealRetirementAge: number;
  monthlyAtRetirement: number;
  currentlySaved: number;
  amountAddingMonthly: number;
  referredBy: string;
  isV2?: boolean;
}

const HOST =
  process.env.API_HOST ?? "https://retirement-calculator-api.vercel.app";

type State = "idle" | "pending" | "success" | "error";

export const useSubmit = () => {
  const [state, setState] = React.useState<State>("idle");

  const submit = useCallback(async (props: UseSubmitProps) => {
    try {
      setState("pending");
      await axios.post(`${HOST}/submit`, props);
      setState("success");
    } catch {
      setState("error");
    }
  }, []);

  return { state, submit };
};
