import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { getResult } from "./hooks/useGetResult";

export interface Input {
  age: number;
  income: number;
  idealRetirementAge: number;
  monthlyRetirementSpending: number;
  currentSavings: number;
  monthlySavings: number;
}

export interface Result {
  monthlySavings: number;
  neededMonthlySavings: number;
}

export interface ContactFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  referredBy: string;
}

interface IInputContext {
  result: Result;
  setResult: (result: Result) => void;
  contactFormInput?: ContactFormInput;
  setContactFormInput?: (input: ContactFormInput) => void;
  contactUsModal: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export const initialInput = {
  age: 25,
  income: 120000,
  idealRetirementAge: 60,
  monthlyRetirementSpending: 10000,
  currentSavings: 30000,
  monthlySavings: 1000,
};

export const initialResult: Result = getResult(initialInput);

const initialContext: IInputContext = {
  result: initialResult,
  setResult: () => {},
  contactUsModal: {
    isOpen: false,
    onOpen: () => {},
    onClose: () => {},
  },
};

const InputContext = createContext<IInputContext>(initialContext);

export const InputProvider = ({
  input,
  children,
}: {
  input: Input;
  setInput: (input: Input) => void;
  children: React.ReactNode;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [result, setResult] = useState<Result>(initialResult);

  const [contact, setContact] = useState<ContactFormInput | undefined>();

  useEffect(() => {
    setResult(getResult(input));
  }, [input]);

  return (
    <InputContext.Provider
      value={{
        result,
        setResult,
        contactFormInput: contact,
        setContactFormInput: setContact,
        contactUsModal: {
          isOpen,
          onClose,
          onOpen,
        },
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export const useInput = () => {
  const context = React.useContext(InputContext);

  if (!context) {
    throw new Error("useInput must be used within a InputProvider");
  }

  return context;
};
