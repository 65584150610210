import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";
import { useInput } from "../InputContext";
import { useDebounce } from "../useDebounce";
import { fv, pv, pmt } from "financial";

interface ScoreRulerProps {
  percentage: number;
}

const ScoreRuler = ({ percentage }: ScoreRulerProps) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 888px)");

  return (
    <Box>
      <Text fontSize="lg" fontWeight="medium">
        You're {Math.round(percentage * 100)}% to your goal!
      </Text>
      <Box position={"relative"} mt="2">
        <HStack spacing={2} w="100%">
          <Box p={2} borderRadius={4} minH="8" bgColor={"tomato"} w="40%">
            {!isSmallScreen && (
              <Text textColor={"white"} fontWeight="medium">
                Needs attention
              </Text>
            )}
          </Box>
          <Box p={2} borderRadius={4} minH="8" bgColor={"orange"} w="25%">
            {!isSmallScreen && (
              <Text textColor={"white"} fontWeight="medium">
                On your way
              </Text>
            )}
          </Box>
          <Box p={2} borderRadius={4} minH="8" bgColor={"blue.700"} w="20%">
            {!isSmallScreen && (
              <Text textColor={"white"} fontWeight="medium">
                Getting close
              </Text>
            )}
          </Box>
          <Box p={2} borderRadius={4} minH="8" bgColor={"green"} w="15%">
            {!isSmallScreen && (
              <Text textColor={"white"} fontWeight="medium">
                On track
              </Text>
            )}
          </Box>
        </HStack>
        <Box position="absolute" left={0} right={0} top={0} bottom={0}>
          <Box
            w="0"
            h="0"
            borderLeft="8px solid transparent"
            borderRight="8px solid transparent"
            borderTop={"8px solid #fff"}
            position="absolute"
            left={`calc(${percentage * 100}%)`}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export const LIFE_SPAN = 95;

type ResultProps = {
  minWidth?: string | number;
  isV2?: boolean;
};

export const INFLATION = 0.04;
export const ANNUAL_COMPOUND_RATE = 0.08;
export const RETIREMENT_CAP_RATE = 0.05;

const Result = (props: ResultProps) => {
  const {
    result: { monthlySavings, neededMonthlySavings },
    contactUsModal: { onOpen },
  } = useInput();

  const data = useMemo(
    () => [
      { x: "Currently saving", y: monthlySavings, y0: 0 },
      { x: "You need to be saving", y: neededMonthlySavings, y0: 0 },
    ],
    [monthlySavings, neededMonthlySavings]
  );

  const percent = useMemo(
    () => monthlySavings / neededMonthlySavings,
    [monthlySavings, neededMonthlySavings]
  );

  const color = useMemo(() => {
    if (percent <= 0.4) return "tomato";
    else if (percent <= 0.65) return "orange";
    else if (percent <= 0.85) return "#003cb3";
    else return "green";
  }, [percent]);

  return (
    <VStack textAlign={"left"} alignItems={"left"} {...props}>
      <Heading size={"md"}>
        How much you need to save monthly to reach your retirement goal?
      </Heading>
      <Box w="100%">
        <Container maxW="80" centerContent={true}>
          <VictoryChart
            domainPadding={{ x: 80 }}
            animate={{ duration: 500 }}
            style={{
              background: {
                fill: "transparent",
                stroke: "none",
                accentColor: "none",
              },
              parent: {
                fill: "transparent",
                stroke: "none",
              },
            }}
          >
            <VictoryBar
              animate
              barRatio={1}
              style={{
                border: { stroke: "none" },
                labels: {
                  fontSize: 24,
                  fontWeight: "500",
                },
                data: {
                  fill: ({ datum }) =>
                    datum.x === "Currently saving" ? color : "green",
                },
              }}
              data={data}
              labels={[
                `$${monthlySavings}`,
                `$${
                  neededMonthlySavings.toFixed &&
                  neededMonthlySavings.toFixed(2)
                }`,
              ]}
            />
            <VictoryAxis
              style={{
                axis: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
                tickLabels: {
                  fontSize: 18,
                  fontWeight: "600",
                },
              }}
            />
          </VictoryChart>
        </Container>
      </Box>

      <Heading size={"md"}>Retirement savings score</Heading>
      <ScoreRuler percentage={percent} />
      {color === "tomato" && (
        <Text fontSize={20}>
          Let's get Future You{" "}
          <Text
            fontSize={20}
            fontWeight="semibold"
            as="span"
            textColor={"tomato"}
          >
            out of the red.
          </Text>
        </Text>
      )}
      {color === "orange" && (
        <Text fontSize={20}>
          Solid start, but let's{" "}
          <Text
            fontSize={20}
            fontWeight="semibold"
            as="span"
            textColor={"orange"}
          >
            close the gap.
          </Text>
        </Text>
      )}
      {color === "#003cb3" && (
        <Text fontSize={20}>
          You're close. Let's{" "}
          <Text
            fontSize={20}
            fontWeight="semibold"
            as="span"
            textColor={"blue.600"}
          >
            get you on track.
          </Text>
        </Text>
      )}
      {color === "green" && (
        <Text fontSize={20}>
          You're rocking it. Can you{" "}
          <Text
            fontSize={20}
            fontWeight="semibold"
            as="span"
            textColor={"green"}
          >
            boost it even more?
          </Text>
        </Text>
      )}

      <Text>
        There are a few steps you could take to jumpstart your retirement
        savings. Create an account to reduce your bills, eliminate debt and grow
        your money.
      </Text>
      {!props.isV2 && (
        <Button color="white" bgColor={"#005981"} onClick={onOpen}>
          Get Started (It's Free)
        </Button>
      )}
      <Box textAlign="center">
        <Text fontSize="small">
          These calculations are rough estimates and generated from using an{" "}
          {Math.round(ANNUAL_COMPOUND_RATE * 100)}% rate of return and a{" "}
          {Math.round(INFLATION * 100)}% inflation rate.
        </Text>
      </Box>
    </VStack>
  );
};

export default Result;
